/* eslint-disable camelcase */ /* eslint-disable react/jsx-pascal-case */
import { arrayOf } from 'prop-types'
import { useFeatureFlag } from 'configcat-react'

import { useCallback, useMemo, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { MRT_GlobalFilterTextField } from 'material-react-table'
import ListTable from '../ListTable/components/ListTable'

import useTenantTags from './api/useTenantTags'
import TenantManagementActionsCell from '../TableCells/TenantManagementActionsCell/TenantManagementActionsCell'
import TagCheck from './components/TagCheck'
import TenantTagsManageModal from './TenantTagsManageModal'

import TenantManagementSkeleton from '../LoadingSkeletons/TenantManagementSkeleton'
import Alert from '../Alert/Alert'
import TenantTagsCell from '../TableCells/TenantTagsCell/TenantTagsCell'
import { listTenantShape } from '../TenantAlignment/helpers'
import uniqueTenantTags from '../../utils/uniqueTenantTags'

const TenantManagementTable = ({ tenants }) => {
  const navigate = useNavigate()

  const [selectedTenants, setSelectedTenants] = useState([])
  const [actionMenuOpen, setActionMenuOpen] = useState(false)

  const anchorElement = useRef()

  const openOnboarding = () => {
    navigate('/onboarding')
  }

  // partner center tenants only
  const partnerCenterClientIds = tenants
    ?.filter(e => e.partnerCenter === true)
    .map(partner => partner.clientTenantId)

  const { value: enableTenantTags } = useFeatureFlag('enableTenantTags', false)

  const {
    data: tenantTags = [],
    isLoading: isTenantTagsLoading,
    isError: isTenantTagsError,
    error: tenantTagsError,
  } = useTenantTags(enableTenantTags)

  const uniqueTags = uniqueTenantTags({
    tenants,
    tenantTags,
  })

  const MemoizedTagsHeader = useCallback(
    () => (
      <Link
        className='text-blue-500 cursor-pointer hover:underline'
        to='/settings/tenant-tags'
      >
        Tags
      </Link>
    ),
    []
  )

  const columns = useMemo(
    () => [
      {
        header: 'Tenant name',
        accessor: 'tenantFriendlyName',
        initialSort: 'asc',
        size: 10,
        grow: true,
      },
      {
        header: 'Baseline',
        accessor: 'isGoldenTenant',
        cell: ({ row }) => TagCheck(row.original.isGoldenTenant),
        enableSorting: false,
        enableColumnFilter: false,
        maxSize: 10,
        grow: false,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: 'Backups Enabled',
        accessor: 'backupEnabled',
        cell: ({ row }) => TagCheck(row.original.backupEnabled === 1),
        enableSorting: false,
        enableColumnFilter: false,
        maxSize: 10,
        grow: false,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: 'Partner Center',
        accessor: 'partnerCenter',
        cell: ({ row }) => TagCheck(row.original.partnerCenter),
        enableSorting: false,
        enableColumnFilter: false,
        maxSize: 10,
        grow: true,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: 'Tags',
        accessor: 'tags',
        Header: <MemoizedTagsHeader />,
        cell: ({ row }) =>
          TenantTagsCell({
            linkedTenantTagIds: row.original.tags,
            allTenantTags: tenantTags,
            numVisibleTags: 4,
          }),
        filterVariant: 'multi-select',
        filterSelectOptions: uniqueTags.map(tag => ({
          value: tag?.id,
          label: tag?.tag,
        })),
        size: 400,
        minSize: 100,
        maxSize: 500,
        grow: true,
        sx: { overflow: 'hidden', borderLeft: true },
      },
      {
        accessor: 'actions',
        header: '',
        cell: TenantManagementActionsCell,
        enableSorting: false,
        enableColumnFilter: false,
        size: 5,
        maxSize: 50,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
    ],
    [tenantTags, uniqueTags]
  )

  if (isTenantTagsLoading) {
    return <TenantManagementSkeleton />
  }

  if (isTenantTagsError) {
    return (
      <div className='shared-baselines-page md:container mx-auto'>
        <div className='flex items-center shadow-sm bg-white w-full rounded-xl mb-6 py-8 px-10'>
          <Alert
            type='error'
            title={tenantTagsError.response.data.message}
            margin='mb-4'
          >
            {tenantTagsError.response.data.errors?.map(error => (
              <ul key={error}>
                <li>{error}</li>
              </ul>
            ))}
          </Alert>
        </div>
      </div>
    )
  }

  return (
    <ListTable
      data={tenants}
      columns={columns}
      selectedListItems={selectedTenants}
      setSelectedListItems={setSelectedTenants}
      uniqueKey='clientTenantId'
      enableRowSelection
      enableMultiRowSelection
      renderTopToolbar={({ table }) => {
        const numSelectedRows = table.getSelectedRowModel().rows.length
        const selected = table.getSelectedRowModel().rows

        return (
          <div className='flex justify-between'>
            <div className='flex flex-row justify-start items-end mb-4'>
              <div className='mr-2'>
                <MRT_GlobalFilterTextField table={table} />
              </div>

              <div className='flex flex-row gap-4 items-center'>
                <button
                  type='button'
                  className='hover:bg-gray-300 bg-gray-100 w-5 h-5 p-5 rounded-full cursor-pointer inline-flex items-center justify-center'
                  id='user-action-button'
                  aria-expanded={actionMenuOpen}
                  aria-haspopup='true'
                  onClick={() => setActionMenuOpen(!actionMenuOpen)}
                  disabled={numSelectedRows === 0}
                >
                  <FontAwesomeIcon
                    ref={anchorElement}
                    icon={faEllipsisVertical}
                    className={
                      numSelectedRows === 0 ? 'text-gray-400' : 'text-gray-800'
                    }
                  />
                </button>
                <span className='text-[rgb(var(--cyan))]'>{`${numSelectedRows} ${numSelectedRows === 1 ? 'tenant' : 'tenants'} selected`}</span>

                <TenantTagsManageModal
                  menuOpen={actionMenuOpen}
                  setActionMenuOpen={setActionMenuOpen}
                  anchorEl={anchorElement.current}
                  selectedRows={selected}
                  tags={tenantTags}
                />
              </div>
            </div>

            <div className='flex flex-row justify-end items-end mb-4'>
              <button
                type='button'
                className='btn cyan-btn flex items-center mr-2'
                onClick={openOnboarding}
              >
                <FontAwesomeIcon icon={icon({ name: 'user-plus' })} />
                &nbsp;Add Tenants
              </button>
              <button
                type='button'
                className='btn slate-btn mr-3 cyan-btn flex items-center'
                onClick={() =>
                  navigate(
                    `/tenant-refresh?clientTenantId=${partnerCenterClientIds}`
                  )
                }
              >
                <FontAwesomeIcon
                  className='pr-2'
                  icon={icon({ name: 'rotate' })}
                />
                Refresh Partner Center
              </button>
            </div>
          </div>
        )
      }}
    />
  )
}

TenantManagementTable.propTypes = {
  tenants: arrayOf(listTenantShape).isRequired,
}

export default TenantManagementTable
