import { arrayOf, func, shape, string } from 'prop-types'
import Tag from '../../Tag/Tag'
import { hexToRgb } from '../../../utils/colours'

const ListTags = ({ tags, unlinkTag }) => (
  <div
    className='flex flex-col items-start gap-2 border-2 mb-3 p-3 rounded'
    aria-label='selected tags'
  >
    <div className='flex flex-wrap gap-2 items-center'>
      {tags?.map(tag => (
        <Tag
          key={tag.id}
          text={tag.tag}
          colourRGB={hexToRgb(tag.colour)}
          onDelete={() => unlinkTag(tag.id)}
        />
      ))}
      {tags.length === 0 && <p className='text-gray-500'>No selected tags</p>}
    </div>
  </div>
)

const tagShape = shape({
  colour: string.isRequired,
  id: string.isRequired,
  tag: string.isRequired,
})

ListTags.propTypes = {
  tags: arrayOf(tagShape).isRequired,
  unlinkTag: func.isRequired,
}

export default ListTags
