import { useEffect, useState } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import Axios from 'axios'
import {
  commonLoginAuthority,
  configuration,
  cspAuthScopes,
  cspClientId,
  cspGraphAuthScopes,
  delegatedAdminAuthScopes,
  exchangePermissions,
  loggerOptions,
  parallelOnboardingChunkMaxSize,
  tenantAuthScopes,
  tenantClientId,
  tenantDefenderPermissions,
  tenantGraphPermissions,
} from '../config/OnboardingConfig'

const exchangeDirectoryRoleId = '29232cdf-9323-42fd-ade2-1d097af3e4de'

const loadState = (isRefresh = false) => {
  const localState = localStorage.getItem('onboardingState')

  if (!localState || isRefresh)
    return {
      cspEpaAdded: false,
      cspEpaRemoved: false,
      onboardingCode: null,
      customers: null,
      customerProgress: [],
    }

  return JSON.parse(localState)
}

const saveState = (state, isRefresh = false) => {
  if (isRefresh) return state
  localStorage.setItem('onboardingState', JSON.stringify(state))
  return state
}

export const clearLocalOnboardingState = () => {
  localStorage.removeItem('onboardingState')
}

const encodeFormBody = jsonBody => {
  const formBody = []

  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const property in jsonBody) {
    const encodedKey = encodeURIComponent(property)
    const encodedValue = encodeURIComponent(jsonBody[property])
    formBody.push(`${encodedKey}=${encodedValue}`)
  }

  return formBody.join('&')
}

const cspPca = new PublicClientApplication(configuration)

export const useOnboarding = (isRefresh = false) => {
  const [state, setState] = useState(loadState(isRefresh))

  const [error, setError] = useState()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const initialisePca = async () => await cspPca.initialize()
    initialisePca()
  }, [])

  useEffect(() => {
    if (isRefresh) return
    if (
      state.customerProgress.length === 0 ||
      state.customerProgress.some(p => p.status !== 'complete')
    )
      return
    clearLocalOnboardingState()
  }, [isRefresh, state])

  const skipFindCustomers = () => {
    setState(s =>
      saveState(
        { ...s, cspEpaAdded: true, cspEpaRemoved: true, customers: [] },
        isRefresh
      )
    )
  }

  const addCustomer = customer => {
    setState(s =>
      saveState(
        { ...s, customers: [...(s.customers ?? []), customer] },
        isRefresh
      )
    )
  }

  const confirmCustomers = async (
    selectedCustomers,
    onboardingCode = null,
    onboardInParallel = false,
    defaultEmail = ''
  ) => {
    setState(s =>
      saveState(
        {
          ...s,
          onboardingCode,
          onboardInParallel,
          customerProgress: selectedCustomers.map(selectedCustomer => {
            const customer = s.customers.find(
              selectedStateCustomer =>
                selectedStateCustomer.id === selectedCustomer.id &&
                selectedCustomer.partnerCenter ===
                  selectedStateCustomer.partnerCenter
            )
            const output = {
              customerId: selectedCustomer.id,
              partnerCenter: customer.partnerCenter ?? null,
              friendlyName: customer.companyProfile.companyName,
              log: [],
              status: 'idle',
            }
            if (!isRefresh) {
              output.notificationAddress = defaultEmail
              output.clientReference = customer.companyProfile.companyName
                .toLowerCase()
                .split(' ')
                .join('-')
              output.isGoldenTenant = false
            }
            return output
          }),
        },
        isRefresh
      )
    )
  }

  const updateCustomerMetadata = (customerId, metadata) => {
    setState(s =>
      saveState(
        {
          ...s,
          customerProgress: s.customerProgress.map(customer =>
            customer.customerId === customerId
              ? { ...customer, ...metadata }
              : customer
          ),
        },
        isRefresh
      )
    )
  }

  const addToCustomerProgressLog = (
    customerId,
    message,
    status = 'running'
  ) => {
    setState(s =>
      saveState(
        {
          ...s,
          customerProgress: s.customerProgress.map(item =>
            item.customerId === customerId
              ? {
                  ...item,
                  log: [...item.log, { message, status }],
                  status: status ?? item.status,
                }
              : item
          ),
        },
        isRefresh
      )
    )
  }

  const refreshCspPcaCredential = async (
    authority,
    scopes,
    acquireTokenSilent,
    prompt = 'select_account'
  ) => {
    let response

    if (acquireTokenSilent) {
      try {
        response = await cspPca.acquireTokenSilent({
          scopes,
          authority,
        })
      } catch (acquireTokenSilentError) {
        try {
          response = await cspPca.acquireTokenPopup({
            scopes,
            authority,
          })
        } catch (acquireTokenInteractiveError) {
          console.error(acquireTokenInteractiveError)
          setError('Failed to authenticate interactively')
          setLoading(false)
        }
      }
    } else {
      try {
        response = await cspPca.acquireTokenPopup({
          scopes,
          authority,
          prompt,
        })
      } catch (interactiveLoginError) {
        console.error(interactiveLoginError)
        setError('Failed to authenticate interactively')
        setLoading(false)
      }
    }

    if (response !== undefined && authority === commonLoginAuthority) {
      cspPca.setActiveAccount(response.account)
    }

    return response
  }

  const skipCustomer = async customerId => {
    addToCustomerProgressLog(customerId, 'Skipped by user', 'complete')
  }

  const fetchPartnerCenterToken = async accessToken =>
    await fetch('https://api.partnercenter.microsoft.com/generatetoken', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: encodeFormBody({ grant_type: 'jwt_token' }),
    })

  const fetchPartnerCenterCustomers = async token => {
    let customers = []
    const baseUrl =
      'https://api.partnercenter.microsoft.com/v1/customers?size=1000'

    const fetchPage = async (continuationToken = null) => {
      const headers = {
        Authorization: `Bearer ${token}`,
      }

      let targetUrl = baseUrl
      if (continuationToken) {
        headers['MS-ContinuationToken'] = continuationToken
        targetUrl = `${targetUrl}&seekOperation=Next`
      }

      const response = await fetch(targetUrl, { headers })

      const data = await response.json()

      customers = [...customers, ...data.items]

      if (data.continuationToken) {
        return fetchPage(data.continuationToken)
      }

      return customers
    }

    try {
      await fetchPage()

      return customers
    } catch (fetchPartnerCenterCustomersError) {
      console.error(fetchPartnerCenterCustomersError)
      setError({
        title: 'Warning',
        type: 'warning',
        message: <p>Failed to retrieve partner center customers</p>,
      })
      setLoading(false)
      return []
    }
  }

  const fetchPartnerCenterCustomerDetails = async token => {
    let allResults = []

    const fetchPage = async nextUrlToFetchFrom => {
      try {
        const resp = await fetch(nextUrlToFetchFrom, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const respJson = await resp.json()

        allResults = [...allResults, ...respJson.value]

        if (respJson['@odata.nextLink']) {
          return fetchPage(respJson['@odata.nextLink'])
        }

        return allResults
      } catch (delegatedRelationsError) {
        console.error(delegatedRelationsError)
        setError('Error fetching delegated relationships')
        return null
      }
    }

    const initialUrl =
      'https://graph.microsoft.com/beta/tenantRelationships/delegatedAdminRelationships?$filter=status%20eq%20%27active%27&$select=displayName,status,customer'
    return await fetchPage(initialUrl)
  }

  const fetchServicePrincipals = async (accessToken, query) => {
    let response
    try {
      response = await fetch(
        `https://graph.microsoft.com/v1.0/servicePrincipals${query ? `?${query}` : ''}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            ConsistencyLevel: 'eventual',
          },
        }
      )
    } catch (fetchPrincipalsError) {
      console.error(fetchPrincipalsError)
      setError('Failed to call Graph API')
    }
    return response
  }

  const fetchAppRoles = async (accessToken, appId) => {
    let response

    try {
      response = await fetch(
        `https://graph.microsoft.com/v1.0/servicePrincipals(appId='${appId}')/appRoles`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            ConsistencyLevel: 'eventual',
          },
        }
      )
    } catch (appRoleError) {
      console.error(appRoleError)
      setError('Failed to call Graph API')
    }

    return response
  }

  const fetchExchangeDirectoryRole = async accessToken => {
    let response

    try {
      response = await fetch(
        `https://graph.microsoft.com/v1.0/directoryRoles?$filter=roleTemplateId eq '${exchangeDirectoryRoleId}'&$select=id,roleTemplateId`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
    } catch (exchangeDirectoryRoleError) {
      console.error(exchangeDirectoryRoleError)
      setError('Failed to call Graph API')
    }

    return response
  }

  const addExchangeDirectoryRole = async accessToken => {
    let response

    try {
      response = await fetch(
        `https://graph.microsoft.com/v1.0/directoryRoles`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            roleTemplateId: `${exchangeDirectoryRoleId}`,
          }),
        }
      )
    } catch (addExchangeDirectoryRoleError) {
      console.error(addExchangeDirectoryRoleError)
      setError('Failed to call Graph API')
    }

    return response
  }

  const assignExchangeDirectoryRole = async (accessToken, principalId) => {
    let response

    try {
      response = await fetch(
        'https://graph.microsoft.com/v1.0/roleManagement/directory/roleAssignments',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            '@odata.type': '#microsoft.graph.unifiedRoleAssignment',
            roleDefinitionId: exchangeDirectoryRoleId,
            principalId,
            directoryScopeId: '/',
          }),
        }
      )
    } catch (assignExchangeRoleError) {
      console.error(assignExchangeRoleError)
      setError('Failed to call Graph API')
    }

    return response
  }

  const createApplication = async (accessToken, requiredResourceAccess) => {
    let response
    try {
      response = await fetch(`https://graph.microsoft.com/v1.0/applications`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          displayName: 'Inforcer Integration',
          signInAudience: 'AzureADMyOrg',
          requiredResourceAccess,
        }),
      })
    } catch (caError) {
      console.error(caError)
      setError(
        'Failed to create application registration. Please check that you have sufficient permissions.'
      )
    }

    return response
  }

  const updateApplication = async (
    accessToken,
    appId,
    requiredResourceAccess
  ) =>
    fetch(`https://graph.microsoft.com/v1.0/applications(appId='${appId}')`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        requiredResourceAccess,
      }),
    })

  const getApplication = async (accessToken, appId) =>
    fetch(`https://graph.microsoft.com/v1.0/applications(appId='${appId}')`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })

  const createApplicationConsents = async (
    accessToken,
    customerId,
    applicationId
  ) => {
    let response
    try {
      response = await fetch(
        `https://api.partnercenter.microsoft.com/v1/customers/${customerId}/applicationconsents`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            applicationId,
            applicationGrants: [
              {
                enterpriseApplicationId: '00000003-0000-0000-c000-000000000000',
                scope: tenantAuthScopes.toString(),
              },
            ],
          }),
        }
      )
    } catch (createAppConsentsError) {
      console.error(createAppConsentsError)
      setError('Failed to create application consents')
    }

    return response
  }

  const deleteApplicationConsents = async (
    accessToken,
    customerId,
    applicationId
  ) => {
    let response

    try {
      response = await fetch(
        `https://api.partnercenter.microsoft.com/v1/customers/${customerId}/applicationconsents/${applicationId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
    } catch (deleteAppConsentsError) {
      console.error(deleteAppConsentsError)
      setError('Failed to delete application consents')
    }

    return response
  }

  const createServicePrincipal = async (accessToken, appId, tags) => {
    let response
    try {
      response = await fetch(
        `https://graph.microsoft.com/v1.0/servicePrincipals`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            appId,
            tags,
          }),
        }
      )
    } catch (createPrincipalError) {
      console.error(createPrincipalError)
      setError('Failed to create service principal')
    }
    return response
  }

  const createApplicationPassword = async (accessToken, id, displayName) => {
    let response
    try {
      response = await fetch(
        `https://graph.microsoft.com/v1.0/applications(appId='${id}')/addPassword`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            displayName,
          }),
        }
      )
    } catch (createPasswordError) {
      console.error(createPasswordError)
      setError('Failed to create application secret')
    }

    return response
  }

  const addServiceRoleAssignment = async (
    accessToken,
    appId,
    principalId,
    resourceId,
    appRoleId
  ) => {
    let response
    try {
      response = await fetch(
        `https://graph.microsoft.com/v1.0/servicePrincipals(appId='${appId}')/appRoleAssignments`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            principalId,
            resourceId,
            appRoleId,
          }),
        }
      )
    } catch (addRoleAssignmentError) {
      console.error(addRoleAssignmentError)
      setError('Failed to grant administrative approval')
    }

    return response
  }

  const deleteEnterpriseApplication = async (accessToken, id) => {
    let response

    try {
      response = await fetch(
        `https://graph.microsoft.com/v1.0/servicePrincipals(appId='${id}')`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
    } catch (deleteEpaError) {
      console.error(deleteEpaError)
      setError('Failed to delete enterprise application')
    }

    return response
  }

  const reset = () => {
    clearLocalOnboardingState()
    setState(loadState(isRefresh))
    setError(undefined)
  }

  const getPartnerCenterGraphToken = async tenantId => {
    const graphResponse = await refreshCspPcaCredential(
      `https://login.microsoftonline.com/${tenantId}`,
      delegatedAdminAuthScopes,
      true,
      'consent'
    )

    const token = graphResponse.accessToken

    return token
  }

  const getCustomers = async () => {
    setError(undefined)
    setLoading(true)

    try {
      const authResponse = await refreshCspPcaCredential(
        commonLoginAuthority,
        cspAuthScopes,
        false,
        'consent'
      )
      if (authResponse === undefined) {
        setError({
          title: 'Error Authenticating',
          type: 'error',
          message: (
            <p>
              {`We were unable to authenticate this user with the associated
                Public Cloud Application. Ensure you're authenticating using the
                correct Client Admin account`}
            </p>
          ),
        })
        setLoading(false)
        return
      }

      // generate partner center token
      const tokenResponse = await fetchPartnerCenterToken(
        authResponse.accessToken
      )

      if (!tokenResponse.ok) {
        setError({
          title: 'Warning',
          type: 'warning',
          message: <p>Failed to retrieve partner center token</p>,
        })
        setLoading(false)
        return
      }

      const token = (await tokenResponse.json()).access_token

      // retrieve customers with partner center token
      const customers = await fetchPartnerCenterCustomers(token)

      if (customers.length === 0) {
        setError({
          title: 'Warning',
          type: 'warning',
          message: <p>No customers found for this account</p>,
        })
        setLoading(false)
        return
      }

      const { tenantId } = authResponse

      const partnerCenterGraphToken = await getPartnerCenterGraphToken(tenantId)

      const delegatedRelationships = await fetchPartnerCenterCustomerDetails(
        partnerCenterGraphToken
      )

      if (!delegatedRelationships) {
        setError({
          title: 'Error',
          type: 'error',
          message: (
            <p>An error occurred fetching delegated admin relationships</p>
          ),
        })
        setLoading(false)
        return
      }

      // removes orphaned tenants that are missing required data
      const filteredCustomers = customers.filter(
        customer =>
          !!customer.companyProfile?.companyName &&
          !!customer.companyProfile?.domain
      )
      // map each customer against the delegatedRelationships response to determine if there's an active relationship
      // note: status can include 'active' (active GDAP relationship), 'terminated' (no longer active), 'approvalPending' (pending approval)
      // if a tenant *does not* appear in this list, we mark them as `allowDelegatedAccess: false`
      // if a tenant *does* appear in this list, but does *not* have a status of 'active', we mark them as `allowDelegatedAccess: false`
      // otherwise, we mark the tenant as `allowDelegatedAccess: true`
      const customerDetails = filteredCustomers.map(customer => {
        const allFoundRelationships = delegatedRelationships.filter(
          relationship => relationship?.customer?.tenantId === customer.id
        )

        const isActive = allFoundRelationships.some(
          relationship => relationship?.status === 'active'
        )
        return {
          ...customer,
          allowDelegatedAccess: isActive,
        }
      })

      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/list-tenants`
        )
        const data = response.data.Data

        const existingCustomerIds = data.map(c => c.msTenantId) ?? []

        setState(s =>
          saveState(
            {
              ...s,
              cspEpaAdded: true,
              customers: customerDetails.map(c => ({
                ...c,
                onboardDisabled: existingCustomerIds.includes(c.id),
                partnerCenter: true,
              })),
            },
            isRefresh
          )
        )
      } catch (tError) {
        console.error(tError)
        setError({
          title: 'Error',
          type: 'error',
          message: <p>Failed to retrieve existing customers</p>,
        })
      }
    } catch (networkError) {
      console.error(networkError)
      setError({
        title: 'Error',
        type: 'error',
        message: <p>Failed to retrieve existing customers</p>,
      })
    }

    setLoading(false)
  }

  const deleteCspEpa = async () => {
    setError(undefined)
    setLoading(true)

    // re-authenticate CSP admin user with Microsoft Graph permissions
    try {
      const graphLoginResponse = await refreshCspPcaCredential(
        commonLoginAuthority,
        cspGraphAuthScopes,
        true
      )
      if (graphLoginResponse === undefined) {
        setError('Failed to login')
        setLoading(false)
        return
      }

      // attempt to delete the temporary CSP enterprise application
      const deleteApplicationResponse = await deleteEnterpriseApplication(
        graphLoginResponse.accessToken,
        cspClientId
      )

      if (!deleteApplicationResponse.ok) {
        setError({
          title: 'Warning',
          type: 'warning',
          message: (
            <p>
              The Inforcer Enterprise Application used to onboard this tenant
              could not be removed automatically. You can remove this Enterprise
              Application manually via the{' '}
              <a
                target='_blank'
                rel='noreferrer'
                href='https://portal.azure.com/#view/Microsoft_AAD_IAM/StartboardApplicationsMenuBlade/~/AppAppsPreview/menuId~/null'
              >
                Azure Portal.
              </a>
            </p>
          ),
        })
      }

      setState(s =>
        saveState(
          {
            ...s,
            cspEpaRemoved: true,
          },
          isRefresh
        )
      )
    } catch (deleteError) {
      console.error(deleteError)
      setError({
        title: 'Error',
        type: 'error',
        message: (
          <p>
            Failed to remove your CSP enterprise application, please try again
          </p>
        ),
      })
    }

    setLoading(false)
  }

  const deleteTenantEpa = async (graphResponse, customer) => {
    // attempt to remove temporary enterprise application
    const deleteApplicationResponse = await deleteEnterpriseApplication(
      graphResponse.accessToken,
      tenantClientId
    )

    if (!deleteApplicationResponse.ok) {
      setError({
        title: 'Warning',
        type: 'warning',
        message: (
          <p>
            The Inforcer Enterprise Application used to onboard this tenant
            could not be removed automatically. You can remove this Enterprise
            Application manually via the{' '}
            <a
              target='_blank'
              rel='noreferrer'
              href='https://portal.azure.com/#view/Microsoft_AAD_IAM/StartboardApplicationsMenuBlade/~/AppAppsPreview/menuId~/null'
            >
              Azure Portal.
            </a>
          </p>
        ),
      })
    }

    addToCustomerProgressLog(
      customer.id,
      'Removed temporary enterprise application'
    )
  }

  const onboardCustomerGetTenantAdminGraphToken = async customer => {
    // create new PCA for tenant
    const customerPca = new PublicClientApplication({
      auth: {
        clientId: tenantClientId,
        authority: `https://login.microsoftonline.com/${customer.companyProfile.tenantId}`,
      },
      system: {
        loggerOptions,
      },
    })

    try {
      await customerPca.initialize()

      // login to temporary tenant enterprise application
      const graphResponse = await customerPca.loginPopup({
        scopes: tenantAuthScopes,
        prompt: 'select_account',
      })

      if (!graphResponse.accessToken) {
        addToCustomerProgressLog(
          customer.id,
          'Failed to authenticate as administrator',
          'error'
        )
        return null
      }

      return graphResponse
    } catch (graphTokenError) {
      console.error(graphTokenError)
      customerPca.clearCache()
      addToCustomerProgressLog(
        customer.id,
        'Failed to grant administrative approval',
        'error'
      )
      return null
    }
  }

  const onboardCustomerGetCspAdminGraphToken = async customer => {
    try {
      const authResponse = await refreshCspPcaCredential(
        commonLoginAuthority,
        cspAuthScopes,
        true
      )
      if (authResponse === undefined) {
        addToCustomerProgressLog(
          customer.id,
          'Failed to authenticate as administrator',
          'error'
        )
        return null
      }

      const deleteApplicationConsentsResponse = await deleteApplicationConsents(
        authResponse.accessToken,
        customer.companyProfile.tenantId,
        cspClientId
      )

      if (
        !deleteApplicationConsentsResponse.ok &&
        deleteApplicationConsentsResponse.status !== 404
      ) {
        const deleteAppConsentsResponseJson =
          await deleteApplicationConsentsResponse.json()
        const parsedResponse = JSON.parse(
          deleteAppConsentsResponseJson.description
        )

        addToCustomerProgressLog(
          customer.id,
          parsedResponse.error_description,
          'error'
        )
        return null
      }

      const createApplicationConsentsResponse = await createApplicationConsents(
        authResponse.accessToken,
        customer.companyProfile.tenantId,
        cspClientId
      )

      if (!createApplicationConsentsResponse.ok) {
        addToCustomerProgressLog(
          customer.id,
          'Failed to create application consents',
          'error'
        )
        return null
      }

      addToCustomerProgressLog(customer.id, 'Created application consents')

      const graphResponse = await refreshCspPcaCredential(
        `https://login.microsoftonline.com/${customer.companyProfile.tenantId}`,
        tenantAuthScopes,
        true
      )

      if (authResponse === undefined) {
        addToCustomerProgressLog(
          customer.id,
          'Failed to authenticate as administrator on behalf of tenant',
          'error'
        )
        return null
      }

      return graphResponse
    } catch (cspAdminGraphTokenError) {
      console.error(cspAdminGraphTokenError)
      addToCustomerProgressLog(
        customer.id,
        'Failed to grant administrative approval',
        'error'
      )
      return null
    }
  }

  const onboardCustomer = async (customerId, cAppId, partnerCenter) => {
    const customer = state.customers.find(
      c => c.id === customerId && c.partnerCenter === partnerCenter
    )
    const customerProgress = state.customerProgress.find(
      c => c.customerId === customerId && c.partnerCenter === partnerCenter
    )

    if (!customer || !customerProgress) return

    const isPartnerCenterDelegatedAccess =
      customer.partnerCenter === true && customer.allowDelegatedAccess === true

    setLoading(true)
    addToCustomerProgressLog(
      customerId,
      `Starting onboarding for ${customer.companyProfile.companyName}`
    )
    try {
      const graphResponse = !isPartnerCenterDelegatedAccess
        ? await onboardCustomerGetTenantAdminGraphToken(customer)
        : await onboardCustomerGetCspAdminGraphToken(customer)

      if (!graphResponse) {
        return
      }

      let appSp = null
      if (isRefresh) {
        // retrieve inforcer service principal

        const appSpResponse = await fetchServicePrincipals(
          graphResponse.accessToken,
          `$filter=(appId eq '${cAppId}')&$count=true`
        )

        if (!appSpResponse.ok) {
          addToCustomerProgressLog(
            customerId,
            'Failed to call graph API',
            'error'
          )
          return
        }

        const appSpJson = await appSpResponse.json()
        if (appSpJson.value.length > 1) {
          addToCustomerProgressLog(
            customerId,
            'More than one Inforcer Integration detected on this tenant, please remove it manually and try again',
            'error'
          )
          return
        }

        if (appSpJson.value.length !== 1) {
          addToCustomerProgressLog(
            customerId,
            'Could not find Inforcer Integration',
            'error'
          )
          return
        }

        ;[appSp] = appSpJson.value
      }

      addToCustomerProgressLog(customerId, 'Authenticated as administrator')

      // check for a defender service principal
      const defenderSpResponse = await fetchServicePrincipals(
        graphResponse.accessToken,
        `$filter=(DisplayName eq 'WindowsDefenderATP')&$count=true`
      )

      if (!defenderSpResponse.ok) {
        addToCustomerProgressLog(
          customerId,
          'Failed to call graph API',
          'error'
        )
        return
      }

      const defenderSpJson = await defenderSpResponse.json()

      // check for a graph service principal
      const graphSpResponse = await fetchServicePrincipals(
        graphResponse.accessToken,
        `$filter=(DisplayName eq 'Microsoft Graph')&$count=true`
      )

      if (!graphSpResponse.ok) {
        addToCustomerProgressLog(
          customerId,
          'Failed to call Graph API',
          'error'
        )
        return
      }

      const exchangeSpResponse = await fetchServicePrincipals(
        graphResponse.accessToken,
        `$filter=(DisplayName eq 'Office 365 Exchange Online')&$count=true`
      )

      if (!exchangeSpResponse.ok) {
        addToCustomerProgressLog(
          customerId,
          'Failed to call graph API',
          'error'
        )
        return
      }

      const exchangeSpJson = await exchangeSpResponse.json()

      if (exchangeSpJson.value.length !== 1) {
        addToCustomerProgressLog(
          customerId,
          'Could not find Exchange Online Service Principal',
          'error'
        )
        return
      }

      const exchangeSp = exchangeSpJson.value[0]

      const exchangeSpRolesResponse = await fetchAppRoles(
        graphResponse.accessToken,
        exchangeSp.appId
      )

      if (!exchangeSpRolesResponse.ok) {
        addToCustomerProgressLog(
          customerId,
          'Failed to call Graph API',
          'error'
        )
        return
      }

      const exchangeSpRolesJson = await exchangeSpRolesResponse.json()
      const exchangeSpRoles = exchangeSpRolesJson.value

      const exchangeDirectoryRoleResponse = await fetchExchangeDirectoryRole(
        graphResponse.accessToken
      )

      if (!exchangeDirectoryRoleResponse.ok) {
        addToCustomerProgressLog(
          customerId,
          'Failed to call Graph API',
          'error'
        )
        return
      }

      const exchangeDirectoryRoleJson =
        await exchangeDirectoryRoleResponse.json()

      if (exchangeDirectoryRoleJson.value.length === 0) {
        const addExchangeDirectoryRoleResponse = await addExchangeDirectoryRole(
          graphResponse.accessToken
        )

        if (!addExchangeDirectoryRoleResponse.ok) {
          if (addExchangeDirectoryRoleResponse.status === 409) {
            addToCustomerProgressLog(
              customerId,
              'Exchange Directory Role already exists'
            )
          } else {
            addToCustomerProgressLog(
              customerId,
              'Failed to call Graph API',
              'error'
            )
            return
          }
        }
      }

      const graphSpJson = await graphSpResponse.json()

      if (graphSpJson.value.length !== 1) {
        addToCustomerProgressLog(
          customerId,
          'Could not find Graph Service Principal',
          'error'
        )
        return
      }

      const graphSp = graphSpJson.value[0]

      // retrieve the app roles for Microsoft Graph
      const graphSpRolesResponse = await fetchAppRoles(
        graphResponse.accessToken,
        graphSp.appId
      )

      if (!graphSpRolesResponse.ok) {
        addToCustomerProgressLog(
          customerId,
          'Failed to call Graph API',
          'error'
        )
        return
      }

      const graphSpRolesJson = await graphSpRolesResponse.json()
      const graphSpRoles = graphSpRolesJson.value

      // prepare required resource access list based on retrieved service principals
      const requiredResourceAccessList = []

      const graphRequiredResourceAccess = {
        resourceAppId: graphSp.appId,
        resourceAccess: [],
      }

      tenantGraphPermissions.forEach(permission => {
        const reqPermission = graphSpRoles.find(r => r.value === permission)

        if (!reqPermission) {
          addToCustomerProgressLog(
            customerId,
            `App permission ${permission} not found in the Graph Resource API`
          )
          return
        }

        graphRequiredResourceAccess.resourceAccess.push({
          type: 'Role',
          id: reqPermission.id,
        })
      })

      requiredResourceAccessList.push(graphRequiredResourceAccess)

      if (defenderSpJson.value.length === 1) {
        const defenderSp = defenderSpJson.value[0]

        const defenderRequiredResourceAccess = {
          resourceAppId: defenderSp.appId,
          resourceAccess: [],
        }

        // fetch defender app roles
        const defenderSpRolesResponse = await fetchAppRoles(
          graphResponse.accessToken,
          defenderSp.appId
        )

        if (!defenderSpRolesResponse.ok) {
          addToCustomerProgressLog(
            customerId,
            'Failed to call Graph API',
            'error'
          )
          return
        }

        const defenderSpRolesJson = await defenderSpRolesResponse.json()
        const defenderSpRoles = defenderSpRolesJson.value

        tenantDefenderPermissions.forEach(permission => {
          const reqPermission = defenderSpRoles.find(
            r => r.value === permission
          )

          if (!reqPermission) {
            addToCustomerProgressLog(
              customerId,
              `App permission ${permission} not found in the DefenderATP API`
            )
            return
          }

          defenderRequiredResourceAccess.resourceAccess.push({
            type: 'Role',
            id: reqPermission.id,
          })
        })

        requiredResourceAccessList.push(defenderRequiredResourceAccess)
      }

      const exchangeRequiredResourceAccess = {
        resourceAppId: exchangeSp.appId,
        resourceAccess: [],
      }

      exchangePermissions.forEach(permission => {
        const reqPermission = exchangeSpRoles.find(r => r.value === permission)

        if (!reqPermission) {
          addToCustomerProgressLog(
            customerId,
            `App permission ${permission} not found in the Exchange Online API`
          )
          return
        }

        exchangeRequiredResourceAccess.resourceAccess.push({
          type: 'Role',
          id: reqPermission.id,
        })
      })

      requiredResourceAccessList.push(exchangeRequiredResourceAccess)

      // create the app registration
      let appRegistration = null

      if (isRefresh) {
        const updateAppResponse = await updateApplication(
          graphResponse.accessToken,
          appSp.appId,
          requiredResourceAccessList
        )

        if (!updateAppResponse.ok) {
          addToCustomerProgressLog(
            customerId,
            'Failed to update application registration',
            'error'
          )
          return
        }

        const appResponse = await getApplication(
          graphResponse.accessToken,
          appSp.appId
        )

        if (!updateAppResponse.ok) {
          addToCustomerProgressLog(
            customerId,
            'Failed to retrieve application registration',
            'error'
          )
          return
        }

        addToCustomerProgressLog(customerId, 'Updated application registration')

        appRegistration = await appResponse.json()
      } else {
        const createAppResponse = await createApplication(
          graphResponse.accessToken,
          requiredResourceAccessList
        )

        if (!createAppResponse.ok) {
          if (createAppResponse.status === 403) {
            addToCustomerProgressLog(
              customerId,
              'Failed to create application registration due to a permissions error. Please check that you have sufficient permissions in the tenant.',
              'error'
            )
          } else {
            addToCustomerProgressLog(
              customerId,
              'Failed to create application registration.',
              'error'
            )
          }
          setLoading(false)
          return
        }

        addToCustomerProgressLog(customerId, 'Created application registration')

        appRegistration = await createAppResponse.json()
      }

      let appSecret = null
      if (!isRefresh) {
        // create application secret
        const createPasswordResponse = await createApplicationPassword(
          graphResponse.accessToken,
          appRegistration.appId,
          'AppAccessKey'
        )

        if (!createPasswordResponse.ok) {
          addToCustomerProgressLog(
            customerId,
            'Failed to create application secret',
            'error'
          )
          return
        }

        addToCustomerProgressLog(customerId, 'Created application secret')

        appSecret = await createPasswordResponse.json()

        // create service principal for app registration
        const createServicePrincipalResponse = await createServicePrincipal(
          graphResponse.accessToken,
          appRegistration.appId,
          ['WindowsAzureActiveDirectoryIntegratedApp']
        )

        if (!createPasswordResponse.ok) {
          addToCustomerProgressLog(
            customerId,
            'Failed to create service principal'
          )
          return
        }

        appSp = await createServicePrincipalResponse.json()
      }

      const existingAppRoleAssignmentsResponse = await fetch(
        `https://graph.microsoft.com/v1.0/servicePrincipals(appId='${appSp.appId}')/appRoleAssignments`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${graphResponse.accessToken}`,
          },
        }
      )

      if (!existingAppRoleAssignmentsResponse.ok) {
        addToCustomerProgressLog(
          customerId,
          `Failed to get App Role Assignments for app ${appSp.appId}`,
          'error'
        )
      }

      const existingAppRoleAssignments =
        await existingAppRoleAssignmentsResponse.json()

      // grant permissions for the required resources
      // Pretty sure this whole section is being called asynchronously because it's in a *nested* forEach loop
      // which is potentially causing a hard to debug race condition. This desperately needs to be fixed
      try {
        for (const resourceAppAccess of appRegistration.requiredResourceAccess) {
          const resourceAppResponse = await fetchServicePrincipals(
            graphResponse.accessToken,
            `$filter=(AppId eq '${resourceAppAccess.resourceAppId}')&$count=true`
          )
          const resourceApp = (await resourceAppResponse.json()).value[0]

          // Add a comment for the love of all that is pure in this world
          const missingAppRoleAssignments =
            resourceAppAccess.resourceAccess.filter(
              requiredRole =>
                !existingAppRoleAssignments.value.some(
                  existingRole => existingRole.appRoleId === requiredRole.id
                )
            )

          for (const permission of missingAppRoleAssignments) {
            if (permission.type !== 'Role') continue

            const grantPermissionResponse = await addServiceRoleAssignment(
              graphResponse.accessToken,
              appSp.appId,
              appSp.id,
              resourceApp.id,
              permission.id
            )

            if (!grantPermissionResponse.ok)
              throw new Error('Failed to grant administrative approval')
          }
        }

        addToCustomerProgressLog(
          customerId,
          'Successfully granted all permissions'
        )
      } catch (onboardCustomerError) {
        console.error(onboardCustomerError)
        addToCustomerProgressLog(
          customerId,
          'Failed to grant administrative approval',
          'error'
        )

        return
      }

      // grant the exchange role to the Inforcer Integration
      const exchangeRoleAssignmentResponse = await assignExchangeDirectoryRole(
        graphResponse.accessToken,
        appSp.id
      )

      if (!exchangeRoleAssignmentResponse.ok) {
        const exchangeRoleAssignmentJson =
          await exchangeRoleAssignmentResponse.json()
        switch (exchangeRoleAssignmentResponse.status) {
          case 400:
            if (
              /conflicting object/.test(
                exchangeRoleAssignmentJson?.error?.message
              )
            ) {
              addToCustomerProgressLog(
                customerId,
                'Exchange Directory Role already assigned'
              )
              break
            } else {
              addToCustomerProgressLog(
                customerId,
                'Failed to grant Exchange Online role',
                'error'
              )
              return
            }
          case 409:
            addToCustomerProgressLog(
              customerId,
              'Exchange Directory Role already assigned'
            )
            break
          default:
            addToCustomerProgressLog(
              customerId,
              'Failed to grant Exchange Online role',
              'error'
            )
            return
        }
      }

      if (!isRefresh) {
        addToCustomerProgressLog(
          customerId,
          'Starting mandatory 20 second wait'
        )

        // eslint-disable-next-line no-promise-executor-return
        await new Promise(r => setTimeout(() => r(null), 20000))

        if (!isPartnerCenterDelegatedAccess)
          await deleteTenantEpa(graphResponse, customer)

        // submit payload to inforcer API
        const response = await Axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/onboardClient`,
          {
            onboardingCode: state.onboardingCode,
            tenantFriendlyName: customerProgress.friendlyName,
            tenantDnsName: customer.companyProfile.domain,
            msTenantId: customer.companyProfile.tenantId,
            partnerCenter: customer.partnerCenter,
            applicationId: appRegistration.appId,
            applicationSecret: appSecret.secretText,
            applicationSecretExpiry: appSecret.endDateTime.split('T')[0],
            isGoldenTenant: customerProgress.isGoldenTenant ? 1 : 0,
            notificationAddress: customerProgress.notificationAddress,
            clientReference: customerProgress.clientReference,
          }
        )

        if (response.status < 200 || response.status >= 300) {
          setError({
            title: 'Error',
            type: 'error',
            message: <p>Failed to submit client to Inforcer backend</p>,
          })
          setLoading(false)
          return
        }

        addToCustomerProgressLog(
          customerId,
          `Onboarding successfully completed for ${customer.companyProfile.companyName}`
        )
      } else {
        try {
          await Axios.post(
            `${process.env.REACT_APP_MIDDLEWARE_URL}/refresh-client-tenant`,
            {
              msTenantId: customer.companyProfile.tenantId,
              partnerCenter: customer.partnerCenter,
            }
          )
        } catch (onboardCustomerError) {
          console.error(onboardCustomerError)
          // TODO: deliberately empty to suppress error when calling temporary refresh endpoint
        }

        addToCustomerProgressLog(
          customerId,
          `Refresh successfully completed for ${customer.companyProfile.companyName}`
        )
      }

      // eslint-disable-next-line no-promise-executor-return

      await new Promise(r => setTimeout(() => r(null), 3000))

      addToCustomerProgressLog(
        customerId,

        `${customer.companyProfile.companyName} marked as done`,

        'complete'
      )
    } catch (err) {
      console.error(err)
      setError({
        title: 'Error',
        type: 'error',
        message: <p>Client onboard failed</p>,
      })
    } finally {
      setLoading(false)
    }
  }

  const onboardAllCustomers = async () => {
    const onboardCustomerTasks = []
    const idleCustomers = state.customerProgress.filter(
      p => p.status === 'idle'
    )

    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/list-tenants`
      )
      const tenants = response.data.Data

      await Promise.all(
        idleCustomers.map(async idleCustomer => {
          onboardCustomerTasks.push(
            onboardCustomer(
              idleCustomer.customerId,
              isRefresh
                ? tenants.find(c => c.msTenantId === idleCustomer.customerId)
                    ?.applicationId
                : null,
              idleCustomer.partnerCenter
            )
          )

          if (
            onboardCustomerTasks.length % parallelOnboardingChunkMaxSize ===
            0
          ) {
            await Promise.all(onboardCustomerTasks)
          }
        })
      )
    } catch (tenantError) {
      console.error(tenantError)
      setError({
        title: 'Error',
        type: 'error',
        message: <p>Failed to retrieve existing customers</p>,
      })
    }

    await Promise.all(onboardCustomerTasks)
  }

  return {
    state,
    error,
    loading,
    skipFindCustomers,
    getCustomers,
    addCustomer,
    deleteCspEpa,
    confirmCustomers,
    updateCustomerMetadata,
    skipCustomer,
    onboardCustomer,
    reset,
    onboardAllCustomers,
  }
}
