import { useFeatureFlag } from 'configcat-react'
import { useMemo, useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowsRotate,
  faCheckSquare,
  faClipboardList,
  faCloudArrowUp,
  faMagnifyingGlass,
  faSquareMinus,
} from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'
import { useQueryClient } from '@tanstack/react-query'
import { arrayOf, func, object } from 'prop-types'
import { Menu } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import AlignmentResultItem from './AlignmentResultItem'
import useTenantCompare from '../../hooks/UseTenantCompare'
import {
  alignmentComparisonSortOptions,
  alignmentPolicySortOptions,
  alignmentScoreFilterOptions,
  alignmentStatusFilterOptions,
} from '../../config/TenantAlignmentConfig'
import usePolicyDiff from '../../hooks/usePolicyDiff'
import AlignmentNestedSimilarPolicies from './AlignmentNestedSimilarPolicies'
import { getPolicyGuid } from './helpers'
import PolicyAlignmentStatusSection from './PolicyAlignmentStatusSection'
import RenamePolicyPopup from './RenamePolicyPopup'
import AlignmentTenantsList from './AlignmentTenantsList/AlignmentTenantsList'
import usePolicyRename from './hooks/usePolicyRename'
import {
  pendingChangesStates,
  usePendingChangesStateContext,
} from '../../contexts/PendingChangesStateContext'
import PolicyFilters from './PolicyFilters'
import { isExchangePolicy } from '../../utils/Policy'

import PolicyDiffViewer from '../PolicyDiff/PolicyDiffViewer'
import Modal from '../Modal/Modal'
import PolicyDiffSkeleton from '../LoadingSkeletons/PolicyDiffSkeleton'
import PolicyDescription from './PolicyDescription'
import Tooltip from '../tooltip/Tooltip'
import Alert from '../Alert/Alert'

import useTenantTags from '../TenantTags/api/useTenantTags'
import TenantTagsSelect from './components/TenantTagsSelect'

/**
 * @param {{
 *  onAcceptDeviation: (obj: any) => void,
 *  onRejectDeviation: (obj: any) => void,
 *  onDeployPolicy: (obj: any) => void,
 *  onDeletePolicy: (obj: any) => void,
 *  onRefreshTenant: (obj: any) => void,
 *  onRefreshBaselineTenant: (obj: any) => void,
 *  tenantBaselines: any[],
 * }} props
 */
const AlignmentByTenant = ({
  onAcceptDeviation,
  onRejectDeviation,
  onDeployPolicy,
  onDeletePolicy,
  tenantBaselines,
}) => {
  const navigate = useNavigate()

  const [tenantSearch, setTenantSearch] = useState('')
  const [openBulkActionMenu, setOpenBulkActionMenu] = useState(false)
  const bulkActionAnchorElement = useRef()

  const [selectedBaselineGroupId, setSelectedBaselineGroupId] = useState(
    tenantBaselines[0]?.clientTenantGroupId
  )

  const [tenantResultSort, setTenantResultSort] = useState(
    alignmentComparisonSortOptions[0]
  )

  const [tenantScoreFilter, setTenantScoreFilter] = useState(
    alignmentScoreFilterOptions[0]
  )
  const [selectedTenantTags, setSelectedTenantTags] = useState([])

  const [baselineTenant, setBaselineTenant] = useState(null)
  const [selectedTenant, setSelectedTenant] = useState(null)

  const [selectedPolicySort, setSelectedPolicySort] = useState(
    alignmentPolicySortOptions[0]
  )

  const [selectedPolicyTypeFilter, setSelectedPolicyTypeFilter] = useState([])

  const [policyAlignmentStatusFilter, setPolicyAlignmentStatusFilter] =
    useState([])

  const [policySearch, setPolicySearch] = useState('')

  const [selectedPolicyIds, setSelectedPolicyIds] = useState([])

  const [policyRenameData, setPolicyRenameData] = useState()

  const [openDiffPopup, setOpenDiffPopup] = useState(false)
  const [policyComparisonIds, setPolicyComparisonIds] = useState(undefined)

  const areComparedTenantsExchange =
    baselineTenant?.exchange && selectedTenant?.exchange

  const { value: enableTenantTags } = useFeatureFlag('enableTenantTags', false)

  // used for refetch logic after performing an action with pendingChanges
  const {
    setPendingChangesState,
    isPolicyIdPendingChanges,
    setPolicyIdsWithPendingChanges,
  } = usePendingChangesStateContext()

  const queryClient = useQueryClient()
  const getPolicyDiff = usePolicyDiff(policyComparisonIds)

  const selectedBaseline = useMemo(
    () =>
      tenantBaselines.find(
        baseline => baseline.clientTenantGroupId === selectedBaselineGroupId
      ),
    [selectedBaselineGroupId, tenantBaselines]
  )

  const { getTenantComparison } = useTenantCompare()

  const renamePolicy = usePolicyRename(() => {
    setPolicyRenameData(null)
  })

  const allPolicies = useMemo(() => {
    if (!getTenantComparison.data) return []
    const {
      matchedOrAccepted,
      deviatedUnaccepted,
      missingFromSubjectUnaccepted,
      missingFromBaselineUnaccepted,
    } = getTenantComparison.data[0]
    return [
      ...matchedOrAccepted,
      ...deviatedUnaccepted,
      ...missingFromSubjectUnaccepted,
      ...missingFromBaselineUnaccepted,
    ].map(policy => ({
      ...policy,
      // deployable if isPolicyDeployable & both tenants are exchange OR it's not an exchange policy
      isExchangeCompatible:
        areComparedTenantsExchange || !isExchangePolicy(policy.policyTypeId),
    }))
  }, [areComparedTenantsExchange, getTenantComparison.data])

  // search results for the policies after applying filters & search query
  const allFilteredPolicies = useMemo(() => {
    const output = allPolicies
      .filter(policy =>
        policy?.policyName?.toLowerCase()?.includes(policySearch?.toLowerCase())
      )
      .filter(
        policy =>
          selectedPolicyTypeFilter.length === 0 ||
          selectedPolicyTypeFilter.some(
            ({ value }) => value === policy.policyTypeName
          )
      )
      .filter(
        ({
          isDeviation,
          isMissingFromSubject,
          isMissingFromBaseline,
          isDeviationAccepted,
        }) =>
          policyAlignmentStatusFilter.length === 0 ||
          policyAlignmentStatusFilter.some(({ value }) => {
            switch (value) {
              case 'aligned':
                return (
                  !isDeviation &&
                  !isMissingFromSubject &&
                  !isMissingFromBaseline &&
                  !isDeviationAccepted
                )
              case 'suggested-policies':
                return isMissingFromSubject
              case 'alignment-required':
                return (
                  (isDeviation || isMissingFromBaseline) &&
                  !isDeviationAccepted &&
                  !isMissingFromSubject
                )
              case 'deviated':
                return isDeviation
              case 'accepted-deviation':
                return isDeviationAccepted
              default:
                return true
            }
          })
      )

    output.sort((a, b) => {
      switch (selectedPolicySort.value) {
        case 'policy-type':
          return a.policyTypeId - b.policyTypeId
        case 'a-z':
        default:
          return a.policyName.localeCompare(b.policyName)
      }
    })

    return output
  }, [
    allPolicies,
    policySearch,
    selectedPolicyTypeFilter,
    policyAlignmentStatusFilter,
    selectedPolicySort.value,
  ])

  // filter available policy types based on contents of selected tenant result
  const availablePolicyTypes = useMemo(() => {
    const uniquePolicyTypes = new Set(
      allPolicies.map(policy => policy.policyTypeName)
    )
    return Array.from(uniquePolicyTypes).sort()
  }, [allPolicies])

  const selectedPolicies = useMemo(
    () =>
      allFilteredPolicies?.filter(policy =>
        selectedPolicyIds.includes(policy.subjectPolicyId ?? policy.policyId)
      ) ?? [],
    [allFilteredPolicies, selectedPolicyIds]
  )

  const deviationPolicies = useMemo(
    () =>
      selectedPolicies
        .filter(
          ({
            isDeviation,
            isMissingFromSubject,
            isDeviationAccepted,
            isPolicyDeviationAcceptable,
          }) =>
            (isDeviation || isMissingFromSubject) &&
            !isDeviationAccepted &&
            isPolicyDeviationAcceptable
        )
        .map(
          ({
            subjectTenantId,
            isMissingFromBaseline,
            policyDataDiff,
            policyName,
            policyTypeId,
            policyGuid,
          }) => ({
            subjectClientTenantId: subjectTenantId,
            policyName,
            policyTypeId,
            baselinePolicyGuid: isMissingFromBaseline ? null : policyGuid,
            subjectPolicyGuid: isMissingFromBaseline
              ? policyGuid
              : policyDataDiff?.[0]?.policyGuid,
          })
        ),

    [selectedPolicies]
  )

  const deployableBaselinePolicies = useMemo(
    () =>
      selectedPolicies.filter(
        ({
          isDeviation,
          isMissingFromSubject,
          isMissingFromBaseline,
          isPolicyDeployable,
        }) =>
          (isDeviation || isMissingFromSubject) &&
          !isMissingFromBaseline &&
          isPolicyDeployable
      ),
    [selectedPolicies]
  )

  const alignmentRequiredPolicies = useMemo(
    () =>
      allFilteredPolicies?.filter(
        ({
          isDeviation,
          isDeviationAccepted,
          isMissingFromBaseline,
          isMissingFromSubject,
        }) =>
          (isDeviation || isMissingFromBaseline) &&
          !isDeviationAccepted &&
          !isMissingFromSubject
      ) ?? [],
    [allFilteredPolicies]
  )

  const suggestedPolicies = useMemo(
    () =>
      allFilteredPolicies?.filter(
        ({ isMissingFromSubject, isDeviationAccepted }) =>
          isMissingFromSubject && !isDeviationAccepted
      ) ?? [],
    [allFilteredPolicies]
  )

  const alignedPolicies = useMemo(
    () =>
      allFilteredPolicies?.filter(
        ({ isDeviationAccepted, isDeviation }) =>
          isDeviationAccepted || !isDeviation
      ) ?? [],
    [allFilteredPolicies]
  )

  const toggleSelectedPolicy = (policyId, selected) => {
    if (!selected) {
      setSelectedPolicyIds(ids => ids.filter(id => policyId !== id))
    } else {
      setSelectedPolicyIds(ids => [...ids, policyId])
    }
  }

  const allPoliciesSelected = useMemo(
    () =>
      selectedPolicyIds.length ===
      allFilteredPolicies?.filter(policy => policy.isExchangeCompatible).length,
    [allFilteredPolicies, selectedPolicyIds.length]
  )

  const toggleAllPoliciesSelected = () => {
    if (allPoliciesSelected) {
      setSelectedPolicyIds([])
    } else {
      setSelectedPolicyIds(
        allFilteredPolicies
          .filter(policy => policy.isExchangeCompatible)
          .map(policy => policy.subjectPolicyId ?? policy.policyId)
      )
    }
  }

  const togglePoliciesInSection = policiesInSection => {
    const sectionPolicyIds = policiesInSection
      .filter(policy => policy.isExchangeCompatible)
      .map(policy => policy.subjectPolicyId ?? policy.policyId)
    // the array operator every in JS returns true if the array is empty and that
    // is the literal opposite behaviour we want in this case
    const allPoliciesInSectionSelected =
      selectedPolicyIds.length === 0
        ? false
        : sectionPolicyIds.every(policyId =>
            selectedPolicyIds.includes(policyId)
          )
    if (allPoliciesInSectionSelected) {
      setSelectedPolicyIds(
        selectedPolicyIds.filter(
          policyId => !sectionPolicyIds.includes(policyId)
        )
      )
    } else {
      // remove any policies that are already selected in this section
      const filteredPolicyIds = selectedPolicyIds.filter(
        policyId => !sectionPolicyIds.includes(policyId)
      )
      setSelectedPolicyIds([...filteredPolicyIds, ...sectionPolicyIds])
    }
  }

  const buildTenantPolicyItem = policy => {
    const policyId = policy.subjectPolicyId || policy.policyId
    return (
      <AlignmentResultItem
        key={policy.policyGuid}
        policy={policy}
        disabled={
          isPolicyIdPendingChanges(policyId) || !policy.isExchangeCompatible
        }
        loading={isPolicyIdPendingChanges(policyId)}
        selected={selectedPolicyIds.includes(policyId)}
        description={
          <PolicyDescription
            policy={policy}
            onViewPolicyComparison={() => {
              setPolicyComparisonIds({
                baselinePolicyId: policy.policyId,
                subjectPolicyId: policy.subjectPolicyId,
                isOverride: policy.isOverride,
              })
              setOpenDiffPopup(true)
            }}
            baselineTenant={baselineTenant}
            selectedTenant={selectedTenant}
          />
        }
        error={policy?.errorMessage}
        footer={
          policy.similarPolicies ? (
            <AlignmentNestedSimilarPolicies
              similarPolicies={policy.similarPolicies}
              parentPolicyId={policy.policyId}
              alignedThreshold={selectedBaseline.alignedThreshold}
              semiAlignedThreshold={selectedBaseline.semiAlignedThreshold}
              onRenamePolicy={similarPolicy => {
                setPolicyRenameData({
                  baselineGroupId: selectedBaselineGroupId,
                  clientTenantId: selectedTenant.clientTenantId,
                  targetPolicy: similarPolicy,
                  baselinePolicy: policy,
                })
              }}
              parentPolicyTypeId={policy.policyTypeId}
            />
          ) : null
        }
        onAcceptDeviation={() =>
          onAcceptDeviation({
            baseline: baselineTenant,
            tenant: selectedTenant,
            policies: [
              {
                subjectClientTenantId: policy.subjectTenantId,
                policyName: policy.policyName,
                policyTypeId: policy.policyTypeId,
                baselinePolicyGuid: policy.isMissingFromBaseline
                  ? null
                  : policy.policyGuid,
                subjectPolicyGuid: policy.isMissingFromBaseline
                  ? policy.policyGuid
                  : policy.policyDataDiff?.[0]?.policyGuid,
              },
            ],
            onSuccess: () => {
              getTenantComparison.mutate({
                baselineGroupId: selectedBaselineGroupId,
                baselineClientTenantId:
                  selectedBaseline?.baselineClientTenantId,
                subjectClientTenantId: selectedTenant?.clientTenantId,
              })
              setSelectedPolicyIds([])
            },
          })
        }
        onRejectDeviation={() =>
          onRejectDeviation({
            baseline: baselineTenant,
            tenant: selectedTenant,
            policy,
            onDone: () => {
              getTenantComparison.mutate({
                baselineGroupId: selectedBaselineGroupId,
                baselineClientTenantId:
                  selectedBaseline?.baselineClientTenantId,
                subjectClientTenantId: selectedTenant?.clientTenantId,
              })
            },
          })
        }
        onDeployPolicy={() =>
          onDeployPolicy({
            baseline: baselineTenant,
            tenant: selectedTenant,
            policy,
            onDone: () => {
              setSelectedPolicyIds([])
            },
          })
        }
        onDelete={() => {
          const deletePolicyConfig = {
            tenant: selectedTenant,
            policy,
            policyGuid: getPolicyGuid(policy),
            onDone: () => {
              setSelectedPolicyIds([])
            },
          }
          onDeletePolicy(deletePolicyConfig)
        }}
        onToggleSelect={checked =>
          toggleSelectedPolicy(
            policy.subjectPolicyId || policy.policyId,
            checked
          )
        }
      />
    )
  }

  const buildTenantContent = () => {
    if (!selectedTenant || !baselineTenant) {
      return (
        <div className='w-full h-full flex items-center justify-center'>
          <p>Please select a Tenant</p>
        </div>
      )
    }

    const requiresRefresh = !selectedTenant.lastBackupTimestamp

    if (requiresRefresh) {
      return (
        <div className='w-full h-full flex items-center justify-center'>
          <p>No backups found for the selected tenant. Please press refresh.</p>
        </div>
      )
    }

    return (
      <>
        <h3 className='mb-2'>{selectedTenant?.tenantFriendlyName}</h3>
        <hr />
        <PolicyFilters
          policyTypes={availablePolicyTypes}
          selectedPolicySort={selectedPolicySort}
          setSelectedPolicySort={setSelectedPolicySort}
          selectedPolicyTypeFilter={selectedPolicyTypeFilter}
          setSelectedPolicyTypeFilter={setSelectedPolicyTypeFilter}
          policyAlignmentStatusFilter={policyAlignmentStatusFilter}
          setPolicyAlignmentStatusFilter={setPolicyAlignmentStatusFilter}
        />
        <div className='w-full flex flex-col'>
          <div className='relative flex items-center'>
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className='absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-700 z-1'
            />
            <input
              className='flex-grow focus:outline-blue-400 w-full rounded-md light-grey-bg p-2 pl-10 text-gray-700'
              type='text'
              placeholder='Search'
              aria-label='Search'
              value={policySearch}
              onChange={e => setPolicySearch(e.target.value)}
            />
            {policySearch && (
              <button
                type='button'
                className='absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-700 font-bold'
                onClick={() => setPolicySearch('')}
              >
                &#10005;
              </button>
            )}
          </div>

          <div className='mt-4 mb-2 top-[-12px] max-sm:top-3 gap-1 max-m z-[4] sticky flex flex-wrap flex-row self-end'>
            {getTenantComparison.isPending ? (
              <Skeleton className='self-end' width='150px' height='30px' />
            ) : (
              <>
                <button
                  type='button'
                  className='hover:bg-gray-200 rounded-md leading-none px-2 py-1'
                  onClick={toggleAllPoliciesSelected}
                >
                  {allPoliciesSelected && (
                    <FontAwesomeIcon icon={faCheckSquare} size='lg' />
                  )}
                  {selectedPolicies.length === 0 && (
                    <FontAwesomeIcon icon={faSquare} size='lg' />
                  )}
                  {!allPoliciesSelected && selectedPolicies.length > 0 && (
                    <FontAwesomeIcon icon={faSquareMinus} size='lg' />
                  )}
                </button>
                <Tooltip content='Align Policies to Baseline' position='bottom'>
                  <button
                    type='button'
                    className='hover:bg-gray-200 disabled:hover:bg-white disabled:text-gray-400 rounded-md leading-none px-2 py-1'
                    disabled={deployableBaselinePolicies.length === 0}
                    onClick={() =>
                      onDeployPolicy({
                        tenant: selectedTenant,
                        baseline: baselineTenant,
                        policies: deployableBaselinePolicies,
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faCloudArrowUp} size='xl' />
                    <div className='absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-[rgb(var(--navy))] bg-gray-200 rounded-full -top-1 -right-0'>
                      <strong>{deployableBaselinePolicies.length}</strong>
                    </div>
                  </button>
                </Tooltip>
                <Tooltip content='Accept Deviations' position='bottom'>
                  <button
                    type='button'
                    className='hover:bg-gray-200 disabled:hover:bg-white disabled:text-gray-400 rounded-md leading-none px-2 py-1'
                    disabled={deviationPolicies?.length === 0}
                    onClick={() =>
                      onAcceptDeviation({
                        baseline: baselineTenant,
                        tenant: selectedTenant,
                        policies: deviationPolicies,
                        onSuccess: () => {
                          getTenantComparison.mutate({
                            baselineGroupId: selectedBaselineGroupId,
                            baselineClientTenantId:
                              selectedBaseline?.baselineClientTenantId,
                            subjectClientTenantId:
                              selectedTenant?.clientTenantId,
                          })
                          setSelectedPolicyIds([])
                        },
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faClipboardList} size='xl' />
                    <div className='absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-[rgb(var(--navy))] bg-gray-200 rounded-full -top-1 -right-0'>
                      <strong>{deviationPolicies.length}</strong>
                    </div>
                  </button>
                </Tooltip>
              </>
            )}
            <Menu
              open={openBulkActionMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={() => setOpenBulkActionMenu(false)}
              anchorEl={bulkActionAnchorElement.current}
              MenuListProps={{
                'aria-labelledby': 'bulk-policy-actions',
                disablePadding: true,
              }}
            >
              <div className='flex flex-col items-center p-1 gap-1'>
                <button
                  type='button'
                  className='btn cyan-btn text-xs w-full'
                  disabled={deviationPolicies?.length === 0}
                  onClick={() =>
                    onAcceptDeviation({
                      baseline: baselineTenant,
                      tenant: selectedTenant,
                      policies: deviationPolicies,
                      onSuccess: () => {
                        getTenantComparison.mutate({
                          baselineGroupId: selectedBaselineGroupId,
                          baselineClientTenantId:
                            selectedBaseline?.baselineClientTenantId,
                          subjectClientTenantId: selectedTenant?.clientTenantId,
                        })
                        setSelectedPolicyIds([])
                      },
                    })
                  }
                >
                  {`Accept Deviation${deviationPolicies.length === 1 ? '' : 's'} [${deviationPolicies.length}]`}
                </button>

                <button
                  type='button'
                  className='btn text-xs cyan-btn w-full'
                  disabled={deployableBaselinePolicies.length === 0}
                  onClick={() =>
                    onDeployPolicy({
                      tenant: selectedTenant,
                      baseline: baselineTenant,
                      policies: deployableBaselinePolicies,
                    })
                  }
                >
                  {`Align Policies to Baseline [${deployableBaselinePolicies.length}]`}
                </button>
              </div>
            </Menu>
          </div>

          <PolicyAlignmentStatusSection
            policyAlignmentStatusFilter={policyAlignmentStatusFilter}
            policiesLoading={getTenantComparison.isPending}
            policies={alignmentRequiredPolicies}
            title={alignmentStatusFilterOptions[0].label}
            alignmentStatus={[alignmentStatusFilterOptions[0].value]}
            buildTenantPolicyItem={buildTenantPolicyItem}
            dataTestId='alignment-required-container'
            toggleSelectedPolicyIds={() =>
              togglePoliciesInSection(alignmentRequiredPolicies)
            }
            allPoliciesSelected={
              alignmentRequiredPolicies.length > 0 &&
              alignmentRequiredPolicies
                .filter(policy => policy.isExchangeCompatible)
                .every(policy => {
                  const comparisonId = policy.subjectPolicyId ?? policy.policyId
                  return selectedPolicyIds.includes(comparisonId)
                })
            }
          />

          <PolicyAlignmentStatusSection
            policyAlignmentStatusFilter={policyAlignmentStatusFilter}
            policies={suggestedPolicies}
            policiesLoading={getTenantComparison.isPending}
            title={alignmentStatusFilterOptions[1].label}
            alignmentStatus={[alignmentStatusFilterOptions[1].value]}
            buildTenantPolicyItem={buildTenantPolicyItem}
            dataTestId='suggested-policies-container'
            toggleSelectedPolicyIds={() =>
              togglePoliciesInSection(suggestedPolicies)
            }
            allPoliciesSelected={
              suggestedPolicies.length > 0 &&
              suggestedPolicies
                .filter(policy => policy.isExchangeCompatible)
                .every(policy => selectedPolicyIds.includes(policy.policyId))
            }
          />

          <PolicyAlignmentStatusSection
            policyAlignmentStatusFilter={policyAlignmentStatusFilter}
            policies={alignedPolicies}
            policiesLoading={getTenantComparison.isPending}
            title={alignmentStatusFilterOptions[2].label}
            alignmentStatus={[
              alignmentStatusFilterOptions[2].value,
              alignmentStatusFilterOptions[4].value,
            ]}
            buildTenantPolicyItem={buildTenantPolicyItem}
            dataTestId='aligned-container'
            toggleSelectedPolicyIds={() =>
              togglePoliciesInSection(alignedPolicies)
            }
            allPoliciesSelected={
              alignedPolicies.length > 0 &&
              alignedPolicies
                .filter(policy => policy.isExchangeCompatible)
                .every(policy => {
                  const comparisonId = policy.subjectPolicyId ?? policy.policyId
                  return selectedPolicyIds.includes(comparisonId)
                })
            }
          />
        </div>
      </>
    )
  }

  const {
    data: tenantTags,
    isLoading,
    isError,
    error,
  } = useTenantTags(enableTenantTags)

  if (isLoading) {
    return <Skeleton count={5} />
  }

  if (isError) {
    return (
      <Alert type='error' title='Error fetching tenant tags'>
        <div className='flex flex-col gap-1'>
          {error.response.data.errors.map(errorMessage => (
            <div key={errorMessage}>{errorMessage}</div>
          ))}
        </div>
      </Alert>
    )
  }

  return !tenantBaselines || tenantBaselines?.length === 0 ? (
    <div className='flex flex-col justify-center w-[100%]'>
      <p className='mb-3'>No groups Found. Please add a new group.</p>
      <button
        type='button'
        className='btn cyan-btn flex items-center w-fit'
        onClick={() => {
          navigate('/tenant-management?tab=baselines')
        }}
      >
        &nbsp;Add Group
      </button>
    </div>
  ) : (
    <div className='relative flex w-[100%] max-md:flex-col'>
      <div aria-label='Search panel' className='alignment-search-panel'>
        <div className='flex items-center mb-4'>
          <label
            htmlFor='baselineGrouo'
            id='baselineGroup'
            className='w-[200px] mr-2'
          >
            <b>Baseline Group</b>
          </label>
          <div
            aria-labelledby='baselineGroup'
            className='w-[100%] focus:outline-blue-400 rounded-md text-gray-700 text-left'
          >
            <Select
              id='baselineGroup'
              name='baselineGroupSelect'
              options={tenantBaselines?.map(baseline => ({
                label: baseline.name,
                value: baseline.clientTenantGroupId,
              }))}
              value={{
                label: selectedBaseline?.name,
                value: selectedBaseline?.clientTenantGroupId,
              }}
              onChange={selectedValue => {
                setSelectedBaselineGroupId(selectedValue.value)
                setSelectedTenant(null)
                setPendingChangesState(pendingChangesStates.none)
              }}
            />
          </div>
        </div>
        <div className='flex items-center mb-4'>
          <label
            htmlFor='sortByFilter'
            id='sortByFilter'
            className='w-[200px] mr-2'
          >
            <b>Sort by</b>
          </label>
          <div
            aria-labelledby='sortByFilter'
            className='w-[100%] focus:outline-blue-400 rounded-md text-gray-700 text-left'
          >
            <Select
              id='sortByFilter'
              className='w-[100%] focus:outline-blue-400 rounded-md text-gray-700 text-left'
              value={tenantResultSort}
              options={alignmentComparisonSortOptions}
              onChange={setTenantResultSort}
            />
          </div>
        </div>
        <div className='flex items-center mb-4'>
          <label
            htmlFor='AlignmentStatus'
            id='filterBy'
            className='w-[200px] mr-2'
          >
            <b>Alignment status</b>
          </label>
          <div
            aria-labelledby='filterBy'
            className='w-[100%] focus:outline-blue-400 rounded-md text-gray-700 text-left'
          >
            <Select
              id='AlignmentStatus'
              value={tenantScoreFilter}
              options={alignmentScoreFilterOptions}
              onChange={setTenantScoreFilter}
            />
          </div>
        </div>

        {enableTenantTags && (
          <TenantTagsSelect
            tenantTags={tenantTags}
            selectedTenantTags={selectedTenantTags}
            setSelectedTenantTags={setSelectedTenantTags}
          />
        )}

        <div className='flex items-center justify-between'>
          <div className='relative max-md:w-full mr-4 flex items-center my-6'>
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className='absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-700 z-1'
            />
            <input
              className='flex-grow focus:outline-blue-400 w-full rounded-md light-grey-bg p-2 pl-10 text-gray-700'
              type='text'
              placeholder='Search'
              aria-label='Search'
              value={tenantSearch}
              onChange={e => setTenantSearch(e.target.value)}
            />
            {tenantSearch && (
              <button
                type='button'
                className='absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-700 font-bold'
                onClick={() => setTenantSearch('')}
              >
                &#10005;
              </button>
            )}
          </div>

          <button
            type='button'
            className='btn cyan-btn flex items-center'
            disabled={!selectedBaseline}
            onClick={() => {
              setSelectedTenant(null)
              queryClient.invalidateQueries('tenant-summary')
            }}
          >
            <FontAwesomeIcon icon={faArrowsRotate} className='mr-2' />
            Reload
          </button>
        </div>
        {!selectedBaselineGroupId ? (
          <div>No Baseline Selected</div>
        ) : (
          <AlignmentTenantsList
            tenantTags={tenantTags}
            selectedTenantTags={selectedTenantTags}
            selectedBaseline={selectedBaseline}
            setSelectedTenant={newSelectedTenant => {
              setSelectedPolicyIds([])
              setSelectedTenant(newSelectedTenant)

              // don't refetch unless it's a different tenant or we're not already fetching
              if (
                newSelectedTenant.clientTenantId !==
                  selectedTenant?.clientTenantId ||
                !getTenantComparison.isPending
              )
                getTenantComparison.mutate({
                  baselineGroupId: selectedBaselineGroupId,
                  baselineClientTenantId:
                    selectedBaseline.baselineClientTenantId,
                  subjectClientTenantId: newSelectedTenant.clientTenantId,
                })
              setSelectedTenant(newSelectedTenant)
            }}
            selectedTenant={selectedTenant}
            setBaselineTenant={setBaselineTenant}
            invalidateTenantComparison={() => {
              if (!selectedTenant) return
              getTenantComparison.mutate({
                baselineGroupId: selectedBaselineGroupId,
                baselineClientTenantId:
                  selectedBaseline?.baselineClientTenantId,
                subjectClientTenantId: selectedTenant?.clientTenantId,
              })
              setSelectedPolicyIds([])
            }}
            tenantScoreFilter={tenantScoreFilter}
            sortBy={tenantResultSort}
            searchValue={tenantSearch}
          />
        )}
      </div>
      <div
        className='alignment-content-panel flex-grow'
        aria-label='Content Panel'
      >
        {buildTenantContent()}
      </div>
      <Modal
        isOpen={openDiffPopup}
        setModalOpen={setOpenDiffPopup}
        dialogPanelWidth='w-4/5'
      >
        {getPolicyDiff.isError && (
          <div className='flex items-center shadow-sm bg-white w-full rounded-xl mb-6 py-8 px-10'>
            <Alert
              type='error'
              title={getPolicyDiff.error.response.data.message}
              margin='mb-4'
            >
              {getPolicyDiff.error.response.data.errors?.map(
                policyDiffError => (
                  <ul key={policyDiffError}>
                    <li>{policyDiffError}</li>
                  </ul>
                )
              )}
            </Alert>
          </div>
        )}
        {getPolicyDiff.isPending && <PolicyDiffSkeleton />}
        {getPolicyDiff.isSuccess && (
          <PolicyDiffViewer
            policyDiffTitle='Policy Comparison'
            policyLeft={getPolicyDiff.data?.subjectPolicy}
            leftTitle='Customer Policy'
            policyRight={getPolicyDiff.data?.baselinePolicy}
            rightTitle='Baseline Policy'
          />
        )}
      </Modal>
      <RenamePolicyPopup
        open={!!policyRenameData}
        loading={renamePolicy.isPending}
        error={renamePolicy.error?.message || ''}
        baselinePolicyName={policyRenameData?.baselinePolicy?.policyName}
        targetPolicyName={policyRenameData?.targetPolicy?.policyName}
        onClose={() => setPolicyRenameData(null)}
        onConfirmRename={() => {
          renamePolicy.mutate(policyRenameData)
          setPolicyIdsWithPendingChanges(existingPolicyGuids => [
            ...existingPolicyGuids,
            policyRenameData.targetPolicy.policyId,
          ])
          setPendingChangesState(pendingChangesStates.actionInitiated)
        }}
      />
    </div>
  )
}

AlignmentByTenant.propTypes = {
  onAcceptDeviation: func.isRequired,
  onRejectDeviation: func.isRequired,
  onDeployPolicy: func.isRequired,
  onDeletePolicy: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tenantBaselines: arrayOf(object).isRequired,
}

export default AlignmentByTenant
