import { func, number, shape, string } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import {
  darkenRGBColour,
  isColourDarkRGB,
  lightenRGBColour,
} from '../../utils/colours'

const Tag = ({ text, colourRGB, onDelete }) => {
  const { r, g, b } = colourRGB

  // if colour is dark use as background and lighten for text
  // f colour is light use as background and darken for text
  const {
    r: contrastRed,
    g: contrastGreen,
    b: contrastBlue,
  } = isColourDarkRGB(colourRGB)
    ? lightenRGBColour(colourRGB, 10)
    : darkenRGBColour(colourRGB, 60)

  const backgroundColor = `rgba(${r},${g},${b},0.2)`
  const borderColor = `rgba(${contrastRed},${contrastGreen},${contrastBlue},0.4)`
  const color = `rgb(${contrastRed},${contrastGreen},${contrastBlue})`

  return (
    <div
      className='flex items-center gap-1 rounded text-xs py-1 border h-fit px-1.5 w-fit'
      style={{
        background:
          text?.toLowerCase() === 'taste the rainbow'
            ? 'linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet)'
            : backgroundColor,
        borderColor,
        color,
        textDecorationColor: color,
      }}
    >
      {text}
      {onDelete && (
        <button
          className='h-fit'
          onClick={onDelete}
          aria-label='remove-tag'
          type='button'
        >
          <FontAwesomeIcon
            width={8}
            height={8}
            className='rounded-full mt-1 opacity-70'
            style={{
              borderColor,
              color,
              textDecorationColor: color,
            }}
            icon={faX}
          />
        </button>
      )}
    </div>
  )
}

Tag.defaultProps = {
  onDelete: undefined,
}

Tag.propTypes = {
  text: string.isRequired,
  colourRGB: shape({
    r: number.isRequired,
    g: number.isRequired,
    b: number.isRequired,
  }).isRequired,
  onDelete: func,
}

export default Tag
