import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import useTenants from '../../hooks/UseTenants'
import { useOnboarding } from '../../hooks/UseOnboarding'
import OnboardingSetupAllTenants from '../../components/OnboardingSetupAllTenants'
import OnboardingSetupTenant from '../../components/OnboardingSetupTenant'
import OnboardingRetrieveCustomers from '../../components/OnboardingRetrieveCustomers'
import Alert from '../../components/Alert/Alert'

const TenantRefresh = () => {
  const navigate = useNavigate()

  const [params] = useSearchParams()

  const clientTenantIds = useMemo(
    () => params.get('clientTenantId').split(','),
    [params]
  )

  const { loading: tenantsLoading, tenants } = useTenants()

  const [customers, setCustomers] = useState([])

  const {
    state,
    loading,
    error,
    addCustomer,
    confirmCustomers,
    onboardCustomer,
    onboardAllCustomers,
    skipCustomer,
    skipFindCustomers,
    getCustomers,
  } = useOnboarding(true)

  const isParallelOnboardingEnabled = useMemo(
    () =>
      !customers.some(e => e.partnerCenter === false && e.allowDelegatedAccess),
    [customers]
  )

  useEffect(() => {
    if (state.customers?.length > 0 || !tenants) return

    clientTenantIds.forEach(clientTenantId => {
      const tenant = tenants.find(
        t => t.clientTenantId.toString() === clientTenantId
      )

      if (!tenant) return
      const customer = {
        id: tenant.msTenantId,
        companyProfile: {
          domain: tenant.tenantDnsName,
          companyName: tenant.tenantFriendlyName,
          tenantId: tenant.msTenantId,
          applicationId: tenant.applicationId,
        },
        // partnerCenter: true, // TEMPORARY - force refresh to prompt for global admin credentials
        partnerCenter: tenant.partnerCenter ?? false,
        allowDelegatedAccess: tenant.partnerCenter ?? false,
      }

      addCustomer(customer)

      setCustomers(arr => [...arr, customer])
    })
  }, [addCustomer, customers, clientTenantIds, state.customers, tenants])

  const openTenants = () => {
    navigate('/tenant-management')
  }

  const currentStep = useMemo(() => {
    // OnboardSetupTenant for single refresh
    if (customers.length === 1 && !state.customerProgress.length > 0) {
      return 1
    }
    // OnboardSetupTenant for single refresh
    if (customers.length === 1 && state.customerProgress.length !== 0) {
      return 4
    }

    // confirmCustomers for parallel refresh
    if (
      state.cspEpaAdded &&
      customers.length !== 1 &&
      state.customerProgress.length === 0
    ) {
      return 1
    }

    // Refresh all tenants (start processing)
    if (
      state.customerProgress.length !== 0 &&
      state.customerProgress.some(p => p.status !== 'complete')
    ) {
      return 2
    }
    // No access provided
    if (!state.cspEpaAdded) {
      return 0
    }

    // Display error or success based on the result
    if (
      state.customerProgress.some(
        p => p.status === 'complete' && p.status !== 'error'
      )
    ) {
      return 3
    }
    // Refresh individual tenant
    return 4
  }, [state.cspEpaAdded, state.customerProgress, customers])

  const buildRefreshArea = () => {
    switch (currentStep) {
      case 0:
        return (
          <OnboardingRetrieveCustomers
            onClick={getCustomers}
            title='Retrieve customer information'
            onSkip={skipFindCustomers}
            loading={loading}
            isRefresh
          />
        )

      case 1: {
        const selectedCustomers = customers.map(tenant => {
          if (clientTenantIds.length === 1 && customers.length === 1) {
            return tenant
          }
          const customer = state.customers.find(
            c => c.id === tenant.id && c.allowDelegatedAccess
          )
          return customer ?? tenant
        })

        confirmCustomers(selectedCustomers, null, isParallelOnboardingEnabled)

        break
      }
      case 2:
        return (
          <OnboardingSetupAllTenants
            state={state}
            title='Refresh tenants'
            messageOverride='You are about to refresh all of the partner center tenants with default data. Your credentials may need to be refreshed during this process.'
            onOnboardAllCustomers={onboardAllCustomers}
          />
        )
      case 3:
        return (
          <>
            <h3 className='mb-4'>Refresh completed</h3>
            {state.customerProgress.some(p => p.status === 'complete') && (
              <>
                <p className='mb-6'>Your tenants are now refreshed!</p>
                <div className='mb-4'>
                  <Alert
                    title='The following tenants have been refreshed'
                    type='success'
                  >
                    <ul>
                      {/* render li for customerProgress when status === 'complete */}
                      {state.customerProgress
                        .filter(p => p.status === 'complete')
                        .map(p => (
                          <li key={p.customerId}>{p.friendlyName}</li>
                        ))}
                    </ul>
                  </Alert>
                </div>
              </>
            )}

            {state.customerProgress.some(p => p.status === 'error') && (
              <div className='mb-4'>
                <Alert
                  title='The following tenants could not be refreshed'
                  type='warning'
                >
                  <ul>
                    {/* render li for customerProgress when status === 'error */}
                    {state.customerProgress
                      .filter(p => p.status === 'error')
                      .map(p => (
                        <li key={p.customerId} className='font-semibold'>
                          {p.friendlyName}
                          <ul>
                            {p.log
                              .filter(l => l.status === 'error')
                              .map(l => (
                                <li key={l.message} className='font-light'>
                                  {l.message}
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))}
                  </ul>
                </Alert>
              </div>
            )}
            <button
              type='button'
              className='btn cyan-btn'
              onClick={openTenants}
            >
              View Tenants
            </button>
          </>
        )
      case 4:
        return (
          <OnboardingSetupTenant
            state={state}
            loading={loading}
            onOnboardCustomer={onboardCustomer}
            onSkip={skipCustomer}
            isRefresh
            title='Refresh tenant'
            tenantAppId={customers[0].companyProfile?.applicationId}
          />
        )
      default:
        return null
    }
    return null
  }

  return (
    <div className='ui-panel tenant-management'>
      <div className='heading'>
        <h2>Tenant Refresh</h2>
      </div>
      <Alert title='Please note' type='info' margin='my-6'>
        <p>
          When refreshing permissions on existing tenants, you must sign into
          the relevant global admin account for that tenant. Please only perform
          this if instructed to do so by support.
        </p>
      </Alert>
      <div className='action-body no-progress-bar'>
        {tenantsLoading ? (
          <div id='loading-spinner'>
            <div className='loading' />
          </div>
        ) : (
          <div className='w-[650px]'>
            {error && (
              <Alert title={error?.title} type={error?.type}>
                {error?.message}
              </Alert>
            )}
            {buildRefreshArea()}
          </div>
        )}
      </div>
    </div>
  )
}

export default TenantRefresh
