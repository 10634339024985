// It's in both -> delete from selected
// It's in only target -> delete from selected
// It's in only baseline -> delete from baseline
import {
  arrayOf,
  bool,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types'

export const shouldDeleteFromBaseline = (
  isMissingFromSubject,
  isMissingFromBaseline
) => isMissingFromSubject && !isMissingFromBaseline

// there are 3 cases we need to account for when deleting a policy
// 1 - The policy exists in both the baseline and the selected tenant where we need to use the comparatorPolicyGuid
// 2 - The policy exists only in the customer tenant where we need to use the policyGuid and the value is
//     set to the GUID in the selected tenant
// 3 - The policy exists only in the baseline tenant where we need to use the policyGuid and the value is
//     set to the GUID in the baseline tenant
export const getPolicyGuid = policy =>
  !policy.isMissingFromSubject && !policy.isMissingFromBaseline
    ? policy.comparatorPolicyGuids[0]
    : policy.policyGuid

export const steps = {
  configuration: 'configuration',
  review: 'review',
  final: 'final',
}

export const summaryTenantShape = shape({
  clientTenantId: number.isRequired,
  alignmentTimestamp: string,
  pendingChanges: shape({
    failed: number.isRequired,
    inProgress: number.isRequired,
    hasPendingChanges: bool.isRequired,
    succeeded: number.isRequired,
  }),
  lastBackupTimestamp: string,
  tenantFriendlyName: string.isRequired,
  tenantDnsName: string.isRequired,
  refreshProgress: shape({
    failed: number.isRequired,
    inProgress: number.isRequired,
    succeeded: number.isRequired,
    isRefreshing: bool.isRequired,
  }).isRequired,
  tags: arrayOf(string).isRequired,
  alignmentScore: number,
})

export const listTenantShape = shape({
  partnerCenter: bool.isRequired,
  exchange: bool.isRequired,
  createdDate: string.isRequired,
  clientReference: string.isRequired,
  tenantDnsName: string.isRequired,
  backupEnabled: number.isRequired,
  tenantFriendlyName: string.isRequired,
  clientTenantId: number.isRequired,
  msTenantId: string.isRequired,
  applicationSecretExpiry: string.isRequired,
  applicationId: string.isRequired,
  isGoldenTenant: bool.isRequired,
  clientId: number.isRequired,
  tags: arrayOf(string).isRequired,
})

export const tenantShape = shape({
  clientTenantId: number.isRequired,
  tenantFriendlyName: string.isRequired,
  inforcerManaged: bool.isRequired,
  isBaseline: bool.isRequired,
  isSharedBaseline: bool.isRequired,
  tags: arrayOf(string).isRequired,
})

export const singleFilterShape = shape({
  label: string.isRequired,
  value: string.isRequired,
})

export const selectValueShape = oneOfType([
  arrayOf(singleFilterShape),
  singleFilterShape,
])

// TODO - go back and update any objects to their actual shape
export const policyShape = shape({
  policyId: number,
  subjectPolicyId: number,
  policyNameKeyName: string,
  deviationAcceptedByUsername: string,
  // eslint-disable-next-line react/forbid-prop-types
  policyAssignmentsDiff: arrayOf(object),
  subjectTenantId: number.isRequired,
  policyGuid: string.isRequired,
  isDeviation: bool.isRequired,
  comparatorPolicyGuids: arrayOf(string),
  policyCategoryId: number.isRequired,
  deviationAcceptedTimestamp: string,
  readOnly: bool,
  isPolicyDeviationAcceptable: bool.isRequired,
  deviationAcceptedReason: string,
  isMissingFromBaseline: bool.isRequired,
  matchingBaselinePoliciesCount: number.isRequired,
  state: string.isRequired,
  policyTypeName: string.isRequired,
  policyODataType: string,
  // eslint-disable-next-line react/forbid-prop-types
  policyDataDiff: arrayOf(object),
  isDeviationAccepted: bool.isRequired,
  isPolicyDeployable: bool.isRequired,
  priority: number,
  // eslint-disable-next-line react/forbid-prop-types
  similarPolicies: object,
  stringifiedDiff: string,
  isMissingFromSubject: bool.isRequired,
  policyTypeId: number.isRequired,
  baselineTenantId: number.isRequired,
  deviationAcceptedByUserId: number,
  policyName: string.isRequired,
  matchingSubjectPoliciesCount: number.isRequired,
})

export const tenantTagsShape = {
  value: string,
  label: string,
}

export default { shouldDeleteFromBaseline }
