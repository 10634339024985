import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags } from '@fortawesome/free-solid-svg-icons'
import { Box, Popover, Typography } from '@mui/material'
import { arrayOf, number, oneOfType, shape, string } from 'prop-types'

import { hexToRgb } from '../../../utils/colours'
import Tag from '../../Tag/Tag'

const TagsPopover = ({ tags }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  // Use debounced or delayed handlers to prevent flickering
  const handlePopoverOpen = e => setAnchorEl(e.currentTarget)

  const handlePopoverClose = () => setAnchorEl(null)

  const open = Boolean(anchorEl)

  return (
    <Box display='flex' alignItems='center'>
      {tags.length > 0 && (
        <button
          type='button'
          className='flex items-center justify-center space-x-1 text-xs font-medium text-inforcer-navy hover:opacity-70 hover:duration-200 ease-in-out outline-none'
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <span className='text-sm'>+ {tags.length}</span>
          <FontAwesomeIcon className='pt-[0.5px]' icon={faTags} />
        </button>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        sx={{ pointerEvents: 'none' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <Typography>
            <b>More Tags</b>
          </Typography>
          {tags.map(tag => (
            <div key={tag.id} className='py-1'>
              <Tag text={tag.tag} colourRGB={hexToRgb(tag.colour)} />
            </div>
          ))}
        </Box>
      </Popover>
    </Box>
  )
}

TagsPopover.propTypes = {
  tags: arrayOf(
    shape({
      id: oneOfType([string, number]).isRequired,
      tag: string.isRequired,
      colour: string, // Make sure to include colour if using hexToRgb function
    })
  ).isRequired,
}

export default TagsPopover
